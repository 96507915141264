'use client';

import { ApiPromise } from '@polkadot/api';
import type { UnsubscribePromise } from '@polkadot/api/types';
import { Option, StorageKey } from '@polkadot/types';
import { AnyTuple, Codec } from '@polkadot/types/types';
import { useApi } from 'hooks/useApi';
import { MutableRefObject, useEffect, useRef, useState } from 'react';
import { isDefinedRecursive } from 'utils/common';

interface Tracker {
  trackedApi: ApiPromise | null | undefined;
  isActive: boolean;
  serializedFn: string | null;
  serializedParams: string | null;
  subscriber: UnsubscribePromise | UnsubscribePromiseEntries | null;
}
type TrackerRef = MutableRefObject<Tracker>;

type EntriesResponse<K extends AnyTuple = AnyTuple> = [
  StorageKey<K>,
  unknown,
][];

type UnsubscribePromiseEntries = Promise<EntriesResponse>;

type QueryFn = (
  // biome-ignore lint/suspicious/noExplicitAny: <explanation>
  ...args: any[]
) => UnsubscribePromise | UnsubscribePromiseEntries;

type QueryInfo = {
  section: string;
  method: string;
};

type QueryFnWithInfo = QueryFn & QueryInfo;

// biome-ignore lint/suspicious/noExplicitAny: <explanation>
type TransformFn<T> = (value: any, params: any[]) => T;

type QueryResponse<T extends Codec = Codec> =
  | T
  | Option<T>
  | QueryResponse<T>[];

// biome-ignore lint/suspicious/noExplicitAny: <explanation>
function defaultTransform<T>(resp: any): T {
  return resp as T;
}

function tryToUnwrap<U extends Codec>(res: QueryResponse<U>): U | U[] {
  if (res && typeof res === 'object' && 'unwrapOrDefault' in res) {
    return res.unwrapOrDefault();
  }

  if (Array.isArray(res)) {
    return res.map((item) => tryToUnwrap(item)) as U[];
  }

  return res as U;
}

function subscribe<T>(
  tracker: TrackerRef,
  fn: QueryFn,
  params: unknown[],
  setValue: (value: T) => void,
  transform: TransformFn<T>,
): void {
  unsubscribe(tracker);
  tracker.current.isActive = true;
  tracker.current.subscriber = fn(...params, (resp: QueryResponse): void => {
    if (tracker.current.isActive) {
      setValue(transform(tryToUnwrap(resp), params));
    }
  });
}

function unsubscribe(tracker: TrackerRef): void {
  tracker.current.isActive = false;

  if (tracker.current.subscriber) {
    tracker.current.subscriber
      .then(
        (unsubscribe) =>
          typeof unsubscribe === 'function' && (unsubscribe as VoidFunction)(),
      )
      .catch((error) => {
        console.error('Failed to unsubscribe:', error);
      });
    tracker.current.subscriber = null;
  }
}

export function useApiCall<T>(
  fn?: QueryFn,
  params: unknown[] = [],
  transform: TransformFn<T> = defaultTransform,
): T | undefined {
  const transformRef = useRef(transform);
  const [value, setValue] = useState<T | undefined>();
  const tracker = useRef<Tracker>({
    trackedApi: null,
    isActive: false,
    serializedFn: null,
    serializedParams: null,
    subscriber: null,
  });
  const api = useApi();

  transformRef.current = transform;

  useEffect(() => {
    return (): void => unsubscribe(tracker);
  }, []);

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useEffect(() => {
    if (!fn || !isDefinedRecursive(params)) {
      setValue(undefined);
      tracker.current.serializedParams = null;

      return;
    }

    const { trackedApi, serializedFn, serializedParams, isActive } =
      tracker.current;
    const { section, method } = fn as QueryFnWithInfo;
    const newSerializedFn = `${section}.${method}`;
    const newSerializedParams = JSON.stringify(params);

    if (
      newSerializedFn !== serializedFn ||
      newSerializedParams !== serializedParams ||
      api !== trackedApi ||
      !isActive
    ) {
      setValue(undefined);
      tracker.current.trackedApi = api;
      tracker.current.serializedFn = newSerializedFn;
      tracker.current.serializedParams = newSerializedParams;

      subscribe(tracker, fn, params, setValue, transformRef.current);
    }
  }, [api, fn, ...params]);

  return value;
}
