import {
  ActionIcon,
  Anchor,
  Badge,
  Button,
  Combobox,
  Divider,
  Input,
  MantineColorScheme,
  MantineColorsTuple,
  MantineTheme,
  MantineThemeOther,
  MantineThemeOverride,
  Modal,
  NavLink,
  Pagination,
  Paper,
  Popover,
  ScrollArea,
  Stepper,
  Table,
  TextInput,
  Timeline,
  Tooltip,
  createTheme,
} from '@mantine/core';
import { moonbaseAlpha, moonriver } from '@moonbeam-network/xcm-config';
import { ActionColors, colors } from 'lib/themes/colors';
import { spaceMono } from 'styles/fonts';
import { ReferendumStatus } from 'types/governance';
import classes from '../styles/theme.module.css';

const combinedFontFamily = 'SpaceMonoNumbers, BananaGrotesk, sans-serif';

const TEXT_FONT_FAMILY = combinedFontFamily;
const TITLE_FONT_FAMILY = combinedFontFamily;
const MONO_FONT_FAMILY = spaceMono.style.fontFamily;

type ComputedMantineColorScheme = Exclude<MantineColorScheme, 'auto'>;
type ColorForBothColorSchemes = Record<ComputedMantineColorScheme, string>;

declare module '@mantine/core' {
  export interface MantineThemeOther {
    colors: {
      action: string;
      active: string;
      redBorder: string;
      tooltip: string;
      contrastButton: string;
      thumbsUp: string;
      thumbsDown: string;
      votes: { aye: string; nay: string };
      balanceChartDark: MantineColorsTuple;
      balanceChartLight: MantineColorsTuple;
      announcements: { regular: string; important: string };
      announcementsText: { regular: string; important: string };
      announcementsLink: { regular: string; important: string };
      warning: string;
    };
    themeColors: Record<string, ColorForBothColorSchemes>;
  }
}

function getOther(
  theme: MantineTheme,
  moonChainKey: string,
): MantineThemeOther {
  const {
    black,
    white,
    colors: { dark, gray, green, red, orange },
  } = theme;
  const { balanceChartDark, balanceChartLight } = colors;

  return {
    colors: {
      action: ActionColors[moonChainKey],
      active: theme.colors[theme.primaryColor][8],
      redBorder: colors[moonbaseAlpha.key][9],
      tooltip: dark[9],
      contrastButton: black,
      thumbsUp: green[8],
      thumbsDown: red[8],
      votes: {
        aye: '#53CBC9',
        nay: '#E26560',
      },
      balanceChartDark,
      balanceChartLight,
      announcements: {
        regular: moonChainKey === moonriver.key ? '#06353D' : white,
        important: moonChainKey === moonriver.key ? '#06353D' : white,
      },
      announcementsText: {
        important: moonChainKey === moonriver.key ? black : black,
        regular: moonChainKey === moonriver.key ? black : white,
      },
      announcementsLink: {
        important: moonChainKey === moonriver.key ? black : white,
        regular: moonChainKey === moonriver.key ? black : white,
      },
      warning: orange[8],
    },
    themeColors: {
      disabled: { dark: dark[6], light: dark[0] },
      whiteDark: { dark: dark[6], light: white },
      texts: { dark: white, light: black },
      placeholder: { dark: dark[1], light: gray[7] },
      secondary: { dark: colors.moonDark[9], light: gray[0] },
      navbarBackground: { dark: `rgb(3, 45, 52)`, light: white },
      greyBorder: {
        dark: dark[3],
        light: dark[2],
      },
      secondaryTexts: { dark: dark[1], light: gray[7] },
      footerShadow: { dark: dark[9], light: gray[4] },
      outlineButton: {
        dark: '#032d34',
        light: '#07D3BA1A',
      },
    },
  };
}

export const referendumStatusColors: Record<ReferendumStatus, string> = {
  [ReferendumStatus.SUBMITTED]: 'blue',
  [ReferendumStatus.DECIDING]: 'cyan',
  [ReferendumStatus.CONFIRMING]: 'yellow',
  [ReferendumStatus.CONFIRMED]: 'lime',
  [ReferendumStatus.APPROVED]: 'green',
  [ReferendumStatus.REJECTED]: 'red',
  [ReferendumStatus.TIMED_OUT]: 'gray',
  [ReferendumStatus.CANCELLED]: 'orange',
  [ReferendumStatus.KILLED]: 'violet',
};

export const getMainTheme = (
  theme: MantineTheme,
  moonChainKey: string,
): MantineThemeOverride => {
  const other = getOther(theme, moonChainKey);

  return createTheme({
    spacing: {
      xl: '24px',
    },
    fontFamily: TEXT_FONT_FAMILY,
    fontFamilyMonospace: MONO_FONT_FAMILY,
    primaryColor: moonChainKey,
    colors: colors,
    other,
    defaultRadius: theme.spacing.md,
    headings: {
      fontFamily: TITLE_FONT_FAMILY,
    },
    components: {
      ActionIcon: ActionIcon.extend({
        defaultProps: {
          variant: 'transparent',
        },
        classNames: {
          root: classes.actionIcon,
        },
      }),
      NavLink: NavLink.extend({
        classNames: {
          root: classes.navLinkRoot,
          label: classes.navLinkLabel,
        },
      }),
      Modal: Modal.extend({
        defaultProps: {
          overlayProps: {
            opacity: 0.5,
          },
        },
        classNames: {
          header: classes.modalHeader,
          title: classes.modalTitle,
          content: classes.modalContent,
          inner: classes.modalInner,
        },
      }),
      Timeline: Timeline.extend({
        classNames: {
          itemBullet: classes.timelineBullet,
        },
      }),
      Divider: Divider.extend({
        classNames: {
          root: classes.divider,
        },
      }),
      Button: Button.extend({
        defaultProps: {
          variant: 'outline',
        },
        classNames: {
          root: classes.button,
        },
      }),
      ScrollArea: ScrollArea.extend({
        classNames: {
          scrollbar: classes.scrollbar,
          thumb: classes.scrollbarThumb,
        },
      }),
      Tooltip: Tooltip.extend({
        defaultProps: {
          events: { touch: true, focus: true, hover: true },
          withArrow: true,
        },
      }),
      Table: Table.extend({
        classNames: {
          table: classes.table,
        },
      }),
      Pagination: Pagination.extend({
        classNames: {
          control: classes.paginationControl,
        },
      }),
      Stepper: Stepper.extend({
        classNames: {
          stepDescription: classes.stepDescription,
          stepIcon: classes.stepIcon,
          verticalSeparator: classes.stepperVerticalSeparator,
        },
      }),
      Input: Input.extend({
        classNames: {
          input: classes.input,
        },
      }),
      TextInput: TextInput.extend({
        classNames: {
          input: classes.textInput,
        },
      }),
      Paper: Paper.extend({
        classNames: {
          root: classes.paper,
        },
      }),
      Anchor: Anchor.extend({
        classNames: {
          root: classes.anchor,
        },
      }),
      Popover: Popover.extend({
        defaultProps: {
          shadow: 'md',
        },
        classNames: {
          dropdown: classes.popover,
        },
      }),
      Combobox: Combobox.extend({
        classNames: {
          dropdown: classes.comboboxDropdown,
          option: classes.comboboxOption,
        },
        styles: {
          dropdown: {
            border: 10,
          },
        },
      }),
      Badge: Badge.extend({
        defaultProps: {
          variant: 'light',
        },
      }),
    },
  });
};
