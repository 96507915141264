import { Group, Modal } from '@mantine/core';
import { ActionButton } from 'components/ActionButton';
import { TermsText } from 'components/TermsModal/TermsText';
import { useIsMounted } from 'hooks/useIsMounted';
import { useIsMobile } from 'hooks/useMedia';
import { useTermsAccepted } from 'hooks/useTermsAccepted';
import { useEffect } from 'react';
import { useIsTermsModalOpen } from 'recoil/isTermsModalOpen';

export function TermsModal() {
  const isMobile = useIsMobile();
  const { areTermsAccepted, setTermsAccepted } = useTermsAccepted();
  const { isOpen, close, open } = useIsTermsModalOpen();
  const isMounted = useIsMounted();

  useEffect(() => {
    if (isMounted && !areTermsAccepted) open();
  }, [isMounted, areTermsAccepted, open]);

  const acceptTerms = () => {
    setTermsAccepted();
    close();
  };

  return (
    <Modal
      size={'xl'}
      padding={'xl'}
      opened={isOpen}
      withCloseButton={false}
      onClose={() => undefined}
      yOffset={isMobile ? '15vh' : undefined}
    >
      <TermsText />
      <Group mt={'xs'} justify={'flex-end'}>
        {areTermsAccepted ? (
          <ActionButton onClick={close}>{'Close'}</ActionButton>
        ) : (
          <>
            <ActionButton
              variant={'outline'}
              bg={'transparent'}
              component={'a'}
              href={'https://moonbeam.foundation/'}
            >
              {'Decline'}
            </ActionButton>
            <ActionButton onClick={acceptTerms} variant={'outline'}>
              {'Accept'}
            </ActionButton>
          </>
        )}
      </Group>
    </Modal>
  );
}
