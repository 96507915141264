import {
  moonbaseAlpha,
  moonbeam,
  moonriver,
} from '@moonbeam-network/xcm-config';
import { localDevConfig } from 'configs/chains';

export const logos: Record<string, string> = {
  // this is also in the Ramp page: components/pages/ramp/Ramp.tsx
  [moonbeam.key]: '/images/moonbeam-logo-new-brand.svg',
  [moonriver.key]: '/images/moonriver-logo-new-brand.svg',
  [moonbaseAlpha.key]: '/images/moonbase-logo-new-black.svg',
  [localDevConfig.key]: '/images/moonbase-local-logo.webp',
};

export const darkThemeLogos: Record<string, string> = {
  [moonbeam.key]: '/images/moonbeam-logo-new-white.svg',
  [moonriver.key]: '/images/moonriver-logo-new-white.svg',
  [moonbaseAlpha.key]: '/images/moonbase-logo-new-white.svg',
  [localDevConfig.key]: '/images/moonbase-local-logo.webp',
};
