import { Button, Group, Popover, Text, UnstyledButton } from '@mantine/core';
import { cleanNotifications, showNotification } from '@mantine/notifications';
import {
  mrlPath,
  xcmPath,
} from 'components/AppShell/Navbar/NavbarMenu/NavbarMenu';
import { ConnectModal } from 'components/ConnectWallet/ConnectModal';
import { CBSubscribe } from 'components/ConnectWallet/buttons/CBSubscribe';
import { WalletSetupButton } from 'components/WalletSetup';
import { useWalletNotifications } from 'hooks/notifications/useWalletNotifications';
import { useAddress } from 'hooks/useAddress';
import { useIsMounted } from 'hooks/useIsMounted';
import { useIsMobile } from 'hooks/useMedia';
import { useMoonChain } from 'hooks/useMoonChainConfig';
import { Trans, useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import { useEffect } from 'react';
import { IoChevronDownSharp, IoChevronUpSharp } from 'react-icons/io5';
import { useIsConnectWalletOpen } from 'recoil/isConnectWalletOpen';
import { isBlockedAddress } from 'utils/crypto';
import { useAccount, useDisconnect, useSwitchChain } from 'wagmi';
import { ConnectContent } from './ConnectContent';
import classes from './ConnectWallet.module.css';
import { ConnectedAvatar } from './ConnectedAvatar';
import { HideBalances } from './buttons/HideBalances';

export const ConnectWallet = () => {
  const { isConnected, connector } = useAccount();
  const isMounted = useIsMounted();
  const { t } = useTranslation();
  const isMobile = useIsMobile();
  const { isOpen, toggleEvm, close } = useIsConnectWalletOpen();
  const { chain } = useAccount();
  const config = useMoonChain();
  const address = useAddress();
  const { disconnect } = useDisconnect();
  const { notifyTransactionError } = useWalletNotifications();
  const { switchChain } = useSwitchChain({});
  const router = useRouter();

  const isMrl = router.pathname.includes(mrlPath);
  const isXcmPage = router.pathname.includes(xcmPath);

  // OFAC verification
  useEffect(() => {
    if (isMounted && isBlockedAddress(address)) {
      disconnect();
    }
  }, [address, disconnect, isMounted]);

  useEffect(() => {
    if (connector && chain && chain.id !== config.id && !isMrl && !isXcmPage) {
      const message = (
        <Text size={'sm'} c={'dimmed'}>
          <Trans
            i18nKey={'notifications.wrongChain.message'}
            values={{ network: config.name, wallet: connector.name }}
            components={{
              button: (
                <UnstyledButton
                  onClick={() =>
                    switchChain?.(
                      { chainId: config.id },
                      {
                        onError(error) {
                          notifyTransactionError(error);
                        },
                      },
                    )
                  }
                  className={classes.clickableText}
                />
              ),
            }}
          />
        </Text>
      );

      showNotification({
        styles: {
          root: {
            borderWidth: 2,
            borderColor: 'var(--mantine-primary-color-filled)',
          },
        },
        title: t('notifications.wrongChain.title'),
        message,
        color: 'red',
        autoClose: false,
      });
    }

    return () => {
      cleanNotifications();
    };
  }, [
    chain,
    config,
    connector,
    isMrl,
    t,
    switchChain,
    notifyTransactionError,
    isXcmPage,
  ]);

  if (!isMounted) {
    return null;
  }

  return (
    <>
      {isMobile && <ConnectModal isOpen={isOpen} onClose={close} />}
      <Group gap={'xs'}>
        {isConnected && (
          <>
            <HideBalances />
            <CBSubscribe />
            <ConnectedAvatar />
          </>
        )}
        <Popover
          opened={isOpen}
          onChange={toggleEvm}
          position={'bottom-end'}
          classNames={{ dropdown: classes.dropdown }}
          disabled={isMobile}
          closeOnClickOutside
        >
          <Popover.Target>
            {isConnected ? (
              <Button
                className={classes.openWalletsButton}
                title={isOpen ? 'Close wallets' : 'Open wallets'}
                onClick={toggleEvm}
                px={'sm'}
                fz={'md'}
                variant={'outline'}
              >
                {isOpen ? (
                  <IoChevronUpSharp className={classes.openWalletIcon} />
                ) : (
                  <IoChevronDownSharp className={classes.openWalletIcon} />
                )}
              </Button>
            ) : (
              <WalletSetupButton />
            )}
          </Popover.Target>
          <Popover.Dropdown>
            <ConnectContent />
          </Popover.Dropdown>
        </Popover>
      </Group>
    </>
  );
};
