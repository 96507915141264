import { createEnv } from '@t3-oss/env-nextjs';
import { z } from 'zod';

const booleanSchema = z
  .union([z.boolean(), z.string(), z.undefined()])
  .transform((v) => v === 'true' || v === true)
  .pipe(z.boolean());

// ts-unused-exports:disable-next-line
export const isTest =
  process.env.NODE_ENV === 'test' ||
  process.env.NEXT_PUBLIC_DEPLOYMENT === 'test' ||
  process.env.VITEST === 'true';

const skipValidation = !!process.env.SKIP_ENV_VALIDATION || isTest;
console.log('SKIP_ENV_VALIDATION', skipValidation);

export const env = createEnv({
  /**
   * Specify your server-side environment variables schema here. This way you can ensure the app
   * isn't built with invalid env vars.
   */
  server: {
    NEXT_TELEMETRY_DISABLED: z.enum(['0', '1']).default('1'),
    DYNAMO_DB_DESCRIPTION_TABLE: z.string().min(1),
    DYNAMO_DB_PROPOSAL_VOTES_TABLE: z.string().min(1),
    DYNAMO_DB_VOTE_DELEGATES_TABLE: z.string().min(1),
    DYNAMO_DB_APP_DIR_PROJECTS_TABLE: z.string().min(1),
    DYNAMO_DB_APP_DIR_TVL_TABLE: z.string().min(1),
    DYNAMO_DB_APP_DIR_TX_TABLE: z.string().min(1),
    DYNAMO_DB_APP_DIR_USERS_TABLE: z.string().min(1),
    DYNAMO_DB_CACHE_TABLE: z.string().min(1),
    DYNAMO_DB_SUPPLY_TABLE: z.string().min(1),
    DYNAMO_DB_ACCESS_KEY_ID: z.string().min(1),
    DYNAMO_DB_SECRET: z.string().min(20),
    DYNAMO_DB_REGION: z.string().min(1),
  },

  /**
   * Specify your client-side environment variables schema here. This way you can ensure the app
   * isn't built with invalid env vars. To expose them to the client, prefix them with
   * `NEXT_PUBLIC_`.
   * !WARN: If you add new public variables you might need to add them to GITHUB and to build-and-upload... workflow
   */
  client: {
    NEXT_PUBLIC_DEPLOYMENT: z
      .enum(['local', 'test', 'sandbox', 'production'])
      .optional()
      .default('local'),
    NEXT_PUBLIC_PROJECT_ID: z.string().min(1),
    NEXT_PUBLIC_ABLY_API_KEY: z.string().min(1),
    NEXT_PUBLIC_FLAGSMITH_ENV_ID: z.string().min(1),
    NEXT_PUBLIC_SUBSCAN_API_KEY: z.string().min(1),
    NEXT_PUBLIC_APP_DIRECTORY_IMAGES: z.string().url(),
    NEXT_PUBLIC_GOOGLE_ANALYTICS_ID: z.string().min(1).optional(),
    NEXT_PUBLIC_FLAGSMITH_ENABLED: booleanSchema,
    NEXT_PUBLIC_RAMP_API_KEY: z.string().min(1),
  },

  /**
   * You can't destruct `process.env` as a regular object in the Next.js edge runtime (e.g.
   * middlewares) or client-side so we need to destruct manually.
   */
  runtimeEnv: {
    DYNAMO_DB_DESCRIPTION_TABLE: process.env.DYNAMO_DB_DESCRIPTION_TABLE,
    DYNAMO_DB_PROPOSAL_VOTES_TABLE: process.env.DYNAMO_DB_PROPOSAL_VOTES_TABLE,
    DYNAMO_DB_VOTE_DELEGATES_TABLE: process.env.DYNAMO_DB_VOTE_DELEGATES_TABLE,
    DYNAMO_DB_APP_DIR_PROJECTS_TABLE:
      process.env.DYNAMO_DB_APP_DIR_PROJECTS_TABLE,
    DYNAMO_DB_APP_DIR_TVL_TABLE: process.env.DYNAMO_DB_APP_DIR_TVL_TABLE,
    DYNAMO_DB_APP_DIR_TX_TABLE: process.env.DYNAMO_DB_APP_DIR_TX_TABLE,
    DYNAMO_DB_APP_DIR_USERS_TABLE: process.env.DYNAMO_DB_APP_DIR_USERS_TABLE,
    DYNAMO_DB_CACHE_TABLE: process.env.DYNAMO_DB_CACHE_TABLE,
    DYNAMO_DB_SUPPLY_TABLE: process.env.DYNAMO_DB_SUPPLY_TABLE,
    DYNAMO_DB_ACCESS_KEY_ID: process.env.DYNAMO_DB_ACCESS_KEY_ID,
    DYNAMO_DB_SECRET: process.env.DYNAMO_DB_SECRET,
    DYNAMO_DB_REGION: process.env.DYNAMO_DB_REGION,
    // Next Public
    NEXT_PUBLIC_PROJECT_ID: process.env.NEXT_PUBLIC_PROJECT_ID,
    NEXT_PUBLIC_ABLY_API_KEY: process.env.NEXT_PUBLIC_ABLY_API_KEY,
    NEXT_PUBLIC_FLAGSMITH_ENV_ID: process.env.NEXT_PUBLIC_FLAGSMITH_ENV_ID,
    NEXT_PUBLIC_SUBSCAN_API_KEY: process.env.NEXT_PUBLIC_SUBSCAN_API_KEY,
    NEXT_PUBLIC_APP_DIRECTORY_IMAGES:
      process.env.NEXT_PUBLIC_APP_DIRECTORY_IMAGES,
    NEXT_PUBLIC_DEPLOYMENT: process.env.NEXT_PUBLIC_DEPLOYMENT,
    NEXT_PUBLIC_FLAGSMITH_ENABLED: process.env.NEXT_PUBLIC_FLAGSMITH_ENABLED,
    NEXT_PUBLIC_GOOGLE_ANALYTICS_ID:
      process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS_ID,
    NEXT_PUBLIC_RAMP_API_KEY: process.env.NEXT_PUBLIC_RAMP_API_KEY,
  },
  /**
   * Run `build` or `dev` with `SKIP_ENV_VALIDATION` to skip env validation.
   * This is especially useful for Docker builds.
   */
  skipValidation: skipValidation,
});

export const isDevelopment = env.NEXT_PUBLIC_DEPLOYMENT === 'local';
