import { Container } from '@mantine/core';
import { ethereum, moonbeam } from '@moonbeam-network/xcm-config';
import { MenuItemProps } from 'components/AppShell/Navbar/NavbarMenu/MenuItem';
import { useIsAppDirectoryDisabledFlag } from 'hooks/flags/useIsAppDirectoryDisabledFlag';
import { useIsMrlDisabledFlag } from 'hooks/flags/useIsMrlDisabledFlag';
import { useIsProxyPageDisabledFlag } from 'hooks/flags/useIsProxyPageDisabledFlag';
import { useIsRampPageDisabledFlag } from 'hooks/flags/useIsRampPageDisabledFlag';
import { useIsSquidDisabledFlag } from 'hooks/flags/useIsSquidDisabledFlag';
import { useTracks } from 'hooks/queries/useTracks';
import { useIsMoonbeam, useMoonChainEcosystem } from 'hooks/useMoonChainConfig';
import { useOffsiteLinks } from 'hooks/useOffsiteLinks';
import { useMemo } from 'react';
import { BiCoinStack, BiTransfer } from 'react-icons/bi';
import { BsGlobe2 } from 'react-icons/bs';
import { CiCreditCard1 } from 'react-icons/ci';
import { MdOutlineHowToVote } from 'react-icons/md';
import { SiEnvoyproxy } from 'react-icons/si';
import { TbPlant, TbSmartHome } from 'react-icons/tb';
import { getReferendaTracksIconOrDefault } from 'utils/theme';
import { MenuCategory, MenuCategoryProps } from './MenuCategory';

export const mrlPath = 'mrl';
export const xcmPath = 'xcm';

const openGovTrackMap = new Map<string, string>([
  ['root', 'Root'],
  ['whitelisted_caller', 'Whitelisted'],
  ['general_admin', 'General Admin'],
  ['referendum_canceller', 'Emergency Canceller'],
  ['referendum_killer', 'Emergency Killer'],
  ['fast_general_admin', 'Fast General Admin'],
]);

const appDirItems = [
  {
    label: 'Overview',
    path: `app-dir`,
  },
  {
    label: 'DeFi',
    path: `app-dir?cat=defi`,
  },
  {
    label: 'DEX',
    path: `app-dir?cat=dex`,
  },
  {
    label: 'Bridges',
    path: `app-dir?cat=bridges`,
  },
  {
    label: 'Lending',
    path: `app-dir?cat=lending`,
  },
  {
    label: 'NFTs',
    path: `app-dir?cat=nfts`,
  },
  {
    label: 'Gaming',
    path: `app-dir?cat=gaming`,
  },
  {
    label: 'Social',
    path: `app-dir?cat=social`,
  },
  {
    label: 'Wallets',
    path: `app-dir?cat=wallets`,
  },
  {
    label: 'DAO',
    path: `app-dir?cat=dao`,
  },
  {
    label: 'Other',
    path: `app-dir?cat=other`,
  },
] satisfies MenuItemProps[];

export const NavbarMenu = () => {
  const isMoonbeam = useIsMoonbeam();

  const mrlMenuPath = !isMoonbeam
    ? mrlPath
    : `mrl?from=${ethereum.key}&to=${moonbeam.key}`;
  const xcmLabel = useMoonChainEcosystem();

  const { bridges, collators, orbiter, alerts, discussions, squid } =
    useOffsiteLinks();

  const openGovTracks = useTracks();
  const isAppDirDisabled = useIsAppDirectoryDisabledFlag();
  const isMrlDisabled = useIsMrlDisabledFlag();
  const isProxyPageDisabled = useIsProxyPageDisabledFlag();
  const isSquidDisabled = useIsSquidDisabledFlag();
  const isRampPageDisabled = useIsRampPageDisabledFlag();

  const stakingItems: MenuItemProps[] = useMemo(() => {
    return [
      { label: 'Overview', path: 'staking' },
      { label: 'Collators', link: collators },
      { label: 'Orbiter Program', link: orbiter },
    ];
  }, [collators, orbiter]);

  const crossChainItems: MenuItemProps[] = useMemo(() => {
    const items = [
      { label: xcmLabel, path: xcmPath },
      ...(!isSquidDisabled ? [{ label: 'Squid', link: squid }] : []),
      ...(bridges ? [{ label: 'Other Bridges', link: bridges }] : []),
    ];

    if (!isMrlDisabled) {
      // push as first item
      items.unshift({ label: 'External', path: mrlMenuPath });
    }

    return items;
  }, [bridges, isMrlDisabled, squid, isSquidDisabled, mrlMenuPath, xcmLabel]);

  const governanceLinkItems: MenuItemProps[] = useMemo(() => {
    return [
      { label: 'Discussions', link: discussions },
      ...(alerts ? [{ label: 'alerts', link: alerts }] : []),
    ];
  }, [alerts, discussions]);

  const openGovItems: MenuItemProps[] = useMemo(() => {
    const govItems: MenuItemProps[] = [{ label: 'Overview', path: 'open-gov' }];

    openGovTracks?.forEach((track) => {
      govItems.push({
        label: `${openGovTrackMap.get(track.name)}`,
        path: `open-gov?track=${track.name}`,
        icon: getReferendaTracksIconOrDefault(track.name),
      });
    });

    return govItems;
  }, [openGovTracks]);

  const governanceItems: MenuItemProps[] = useMemo(() => {
    return [...openGovItems, ...governanceLinkItems];
  }, [governanceLinkItems, openGovItems]);

  const onRampItems: MenuItemProps[] = useMemo(() => {
    const items: MenuItemProps[] = [
      { label: 'Transak', link: 'https://global.transak.com/' },
      {
        label: 'Onramp Money',
        link: 'https://onramp.money/main/buy/?appId=1&coinCode=glmr&network=glmr&fiatType=12',
      },
      { label: 'Guardarian', link: 'https://guardarian.com/buy-glmr' },
    ];

    if (!isRampPageDisabled) {
      items.unshift({
        label: 'Ramp',
        path: 'ramp',
      });
    }

    return items;
  }, [isRampPageDisabled]);

  const itemsByCategory: MenuCategoryProps[] = useMemo(() => {
    let memoItems = [
      {
        label: 'Home',
        path: '',
        icon: <TbSmartHome />,
      },
      {
        label: 'Staking',
        items: stakingItems,
        icon: <TbPlant />,
      },
      {
        label: 'Bridges',
        items: crossChainItems,
        icon: <BiTransfer />,
      },
      {
        label: 'Governance',
        items: governanceItems,
        icon: <MdOutlineHowToVote />,
      },
      {
        label: 'Proxy',
        path: 'proxy',
        icon: <SiEnvoyproxy />,
      },
      {
        label: 'On-Ramp',
        items: onRampItems,
        icon: <CiCreditCard1 />,
      },
      ...(!isAppDirDisabled
        ? [
            {
              label: 'Projects',
              items: appDirItems,
              icon: <BsGlobe2 />,
            },
          ]
        : []),
      {
        label: 'Crowdloan Rewards',
        path: 'crowdloan-rewards',
        icon: <BiCoinStack />,
      },
    ];

    // find proxy in array and remove it if disabled
    if (isProxyPageDisabled) {
      memoItems = memoItems.filter((item) => item.path !== 'proxy');
    }

    return memoItems;
  }, [
    crossChainItems,
    governanceItems,
    isAppDirDisabled,
    isProxyPageDisabled,
    stakingItems,
    onRampItems,
  ]);

  return (
    <Container px={26}>
      {itemsByCategory.map((category) => (
        <MenuCategory {...category} key={category.label}></MenuCategory>
      ))}
    </Container>
  );
};
