import { useLocalStorage } from '@mantine/hooks';
import { useMemo } from 'react';

type UseTermsAcceptedResult = {
  areTermsAccepted: boolean | undefined;
  setTermsAccepted: () => void;
};

export function useTermsAccepted(): UseTermsAcceptedResult {
  const [termsAcceptedUtil, setTermsAcceptedUtil] = useLocalStorage<
    string | undefined
  >({
    key: 'terms_accepted_util',
  });

  const areTermsAccepted = useMemo(
    () =>
      termsAcceptedUtil ? new Date(termsAcceptedUtil) > new Date() : false,
    [termsAcceptedUtil],
  );

  const setAccepted = (): void => {
    const date = new Date();

    date.setMonth(date.getMonth() + 3);
    setTermsAcceptedUtil(date.toISOString());
  };

  return {
    areTermsAccepted,
    setTermsAccepted: setAccepted,
  };
}
