import { Group, Stack, Text, UnstyledButton } from '@mantine/core';
import { Avatar } from 'components/Avatar';
import { useAddress } from 'hooks/useAddress';
import { useIsDarkMode } from 'hooks/useIsDarkMode';
import { useThemeColors } from 'hooks/useThemeColors';
import { useSubstrateExtension } from 'recoil/substrateExtension';
import { sliceEllipsis } from 'utils/text';
import { useDisconnect } from 'wagmi';

export const ConnectedAvatar = () => {
  const address = useAddress();
  const { disconnect } = useDisconnect();
  const { setSelectedExtension } = useSubstrateExtension();
  const { themeColors } = useThemeColors();
  const isDark = useIsDarkMode();

  return (
    <Group
      align={'center'}
      style={(theme) => ({
        height: 52,
        padding: theme.spacing.xs,
        backgroundColor: isDark ? 'transparent' : themeColors.navbarBackground,
        borderRadius: theme.radius.md,
        border:
          '1px solid light-dark(var(--mantine-color-dark-4), var(--mantine-color-gray-2))',
      })}
    >
      <Avatar address={address} size={26} />
      <Stack gap={4}>
        <Text size={'10px'} fw={'bold'}>
          {sliceEllipsis(address, 8, 6)}
        </Text>
        <UnstyledButton
          aria-label={'Disconnect'}
          onClick={() => {
            disconnect();
            setSelectedExtension(undefined);
          }}
        >
          <Text size={'10px'} c={themeColors.secondaryTexts}>
            {'Disconnect'}
          </Text>
        </UnstyledButton>
      </Stack>
    </Group>
  );
};
