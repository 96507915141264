import { Anchor, NavLink } from '@mantine/core';
import { useMoonChainKeyParam } from 'hooks/useMoonChainParam';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { ReactNode, useMemo } from 'react';
import { useMobileNavbar } from 'recoil/mobileNavbar';
import { buildFullPath, checkCurrentPath } from 'utils/routes';
import { MenuItem, MenuItemProps } from './MenuItem';
import classes from './NavbarMenu.module.css';

export interface MenuCategoryProps {
  label: string;
  items?: MenuItemProps[];
  icon: ReactNode;
  path?: string;
}

export const MenuCategory = ({
  label,
  items,
  icon,
  path,
}: MenuCategoryProps) => {
  const router = useRouter();
  const moonChainKey = useMoonChainKeyParam();
  const { close } = useMobileNavbar();

  let containsCurrentPath = false;
  const itemComponents = items?.map((item) => {
    if (
      checkCurrentPath(
        router,
        buildFullPath(moonChainKey, item.path),
        item.path,
      )
    ) {
      containsCurrentPath = true;
    }

    return <MenuItem key={item.label} {...item} />;
  });

  const fullPath = buildFullPath(moonChainKey, path);
  const isCurrentPath = useMemo(() => {
    return checkCurrentPath(router, fullPath, path);
  }, [fullPath, path, router]);

  // biome-ignore lint/suspicious/noDoubleEquals: <explanation>
  const isLink = path != undefined;

  const categoryItem = (
    <NavLink
      component={'button'}
      classNames={{
        label: classes.label,
        root: isCurrentPath
          ? classes.active
          : isLink
            ? classes.hover
            : classes.category,
      }}
      my={4}
      h={44}
      label={label.toUpperCase()}
      leftSection={icon}
      childrenOffset={0}
      defaultOpened={containsCurrentPath}
      onClick={isLink ? close : undefined}
    >
      {itemComponents}
    </NavLink>
  );

  return isLink ? (
    <Anchor
      component={Link}
      href={fullPath}
      td={'none'}
      classNames={{ root: classes.linkWrapper }}
    >
      {categoryItem}
    </Anchor>
  ) : (
    categoryItem
  );
};
