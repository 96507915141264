import {
  alphanetRelay,
  kusama,
  moonbaseAlpha,
  moonbeam,
  moonriver,
  polkadot,
} from '@moonbeam-network/xcm-config';
import { isDevelopment } from 'env.mjs';
import { MoonChain } from 'types/MoonChain';

export const moonbeamConfig = new MoonChain({
  ...moonbeam,
  nativeAsset: moonbeam.nativeAsset,
  blockTime: 6,
  explorerUrls: ['https://moonbeam.moonscan.io'],
  minBalance: 100_000_000_000_000_000n,
  polkassemblyUrl: 'https://moonbeam.polkassembly.network',
  relay: polkadot,
  rpcUrls: ['https://rpc.api.moonbeam.network'],
  subscanApiUrl: 'https://moonbeam.api.subscan.io/api',
  subscanUrl: 'https://moonbeam.subscan.io',
});

export const moonriverConfig = new MoonChain({
  ...moonriver,
  nativeAsset: moonriver.nativeAsset,
  blockTime: 6,
  explorerUrls: ['https://moonriver.moonscan.io'],
  minBalance: 10_000_000_000_000_000n,
  polkassemblyOpenGovUrl: 'https://moonriver.polkassembly.io/gov-2',
  polkassemblyUrl: 'https://moonriver.polkassembly.network',
  relay: kusama,
  rpcUrls: ['https://rpc.api.moonriver.moonbeam.network'],
  subscanApiUrl: 'https://moonriver.api.subscan.io/api',
  subscanUrl: 'https://moonriver.subscan.io',
});

export const moonbaseConfig = new MoonChain({
  ...moonbaseAlpha,
  nativeAsset: moonbaseAlpha.nativeAsset,
  blockTime: 6,
  explorerUrls: ['https://moonbase.moonscan.io'],
  minBalance: 10_000_000_000_000_000n,
  polkassemblyUrl: 'https://moonbase.polkassembly.network',
  polkassemblyApiParam: 'moonbase',
  relay: alphanetRelay,
  rpcUrls: ['https://rpc.api.moonbase.moonbeam.network'],
  subscanApiUrl: 'https://moonbase.api.subscan.io/api',
  subscanUrl: 'https://moonbase.subscan.io',
});

export const localDevConfig = new MoonChain({
  ...moonbaseConfig,
  nativeAsset: moonbaseAlpha.nativeAsset,
  name: 'Local Node Dev',
  key: 'local-dev',
  id: 1281,
  rpc: 'http://127.0.0.1:9944',
  ws: ['ws://0.0.0.0:9944'],
  genesisHash:
    '0xb806c11af56169d8f389f6022744ca8a662c33817c4651b27e6cb66cd98857d6',
  parachainId: 0,
  ss58Format: 1281,
  blockTime: 6,
});

const devKeyConfig = isDevelopment
  ? { [localDevConfig.key]: localDevConfig }
  : {};

export const moonChainsKeys = [moonbeam.key, moonriver.key, moonbaseAlpha.key];

if (isDevelopment) {
  moonChainsKeys.push(localDevConfig.key);
}

export const moonChainConfigs: Record<string, MoonChain> = {
  [moonbeam.key]: moonbeamConfig,
  [moonriver.key]: moonriverConfig,
  [moonbaseAlpha.key]: moonbaseConfig,
  // biome-ignore lint/suspicious/noExplicitAny: <explanation>
  ...(devKeyConfig as any),
};

export const xcmDisclosures: Partial<Record<string, string>> = {};
