import { Group, Modal } from '@mantine/core';
import { ActionButton } from 'components/ActionButton';
import { CookieConsentModalText } from 'components/CookieConsentModal/CookieConsentModalText';
import { useCookiesAccepted } from 'hooks/useCookiesAccepted';
import { useIsMounted } from 'hooks/useIsMounted';
import { useIsMobile } from 'hooks/useMedia';

export function CookieConsentModal() {
  const isMobile = useIsMobile();

  const isMounted = useIsMounted();

  const { isCookieAccepted, setCookieAccepted, setCookieRejected } =
    useCookiesAccepted();

  return (
    <Modal
      size={'xl'}
      padding={'xl'}
      opened={isMounted && isCookieAccepted === undefined}
      withCloseButton={false}
      onClose={() => undefined}
      yOffset={isMobile ? '15vh' : undefined}
      centered
    >
      <CookieConsentModalText />
      <Group mt={'xs'} justify={'flex-end'}>
        <>
          <ActionButton
            onClick={setCookieRejected}
            variant={'outline'}
            bg={'transparent'}
          >
            {'Reject All Cookies'}
          </ActionButton>
          <ActionButton onClick={setCookieAccepted} variant={'outline'}>
            {'Accept All Cookies'}
          </ActionButton>
        </>
      </Group>
    </Modal>
  );
}
