import { Button, ButtonProps, Text } from '@mantine/core';
import { IoWallet } from 'react-icons/io5';
import { useIsConnectWalletOpen } from 'recoil/isConnectWalletOpen';
import classes from './ConnectEvmWallet.module.css';

export const ConnectEvmWallet = ({ ...others }: ButtonProps) => {
  const { openEvm } = useIsConnectWalletOpen();

  return (
    <Button
      size={'compact-md'}
      styles={{ root: { height: 22 }, label: { fontSize: '12px' } }}
      className={classes.buttonOutline}
      onClick={openEvm}
      variant="outline"
      leftSection={<IoWallet className={classes.color} />}
      {...others}
    >
      <Text className={classes.color} fz={'12px'}>
        {'Connect wallet'}
      </Text>
    </Button>
  );
};
