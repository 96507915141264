import {
  MantineColorScheme,
  MantineProvider,
  localStorageColorSchemeManager,
} from '@mantine/core';

import '@mantine/carousel/styles.css';
import '@mantine/core/styles.css';
import '@mantine/dates/styles.css';
import '@mantine/notifications/styles.css';
import '@mantine/tiptap/styles.css';
import '@mantine/charts/styles.css';

import { Notifications } from '@mantine/notifications';
import { GoogleAnalytics } from '@next/third-parties/google';
import '@polkadot/types-augment/lookup';
import { AppShell } from 'components/AppShell';
import { CookieConsentModal } from 'components/CookieConsentModal';
import { TermsModal } from 'components/TermsModal';
import { WalletSetup } from 'components/WalletSetup';
import flagsmith from 'flagsmith/isomorphic';
import { FlagsmithProvider } from 'flagsmith/react';
import { IState } from 'flagsmith/types';
import { useCookiesAccepted } from 'hooks/useCookiesAccepted';
import { useMainTheme } from 'hooks/useMainTheme';
import { cssVariablesResolver } from 'lib/themes/cssVariablesResolver';
import { GetServerSideProps } from 'next';
import { appWithTranslation } from 'next-i18next';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import { AppProps } from 'next/app';
import Head from 'next/head';
import { ApiProvider } from 'providers/ApiProvider';
import { EvmWalletProvider } from 'providers/EvmWalletProvider';
import { RecoilRoot } from 'recoil';
import nextI18NextConfig from '../next-i18next.config.cjs';
import '../styles/global.css';
import { env } from 'env.mjs';
import { TRPCReactProvider } from 'lib/trpc/react';

interface Props extends AppProps {
  persistedColorScheme: MantineColorScheme;
  flagsmithState: IState<string>;
}

const NEXT_PUBLIC_FLAGSMITH_ENV_ID = env.NEXT_PUBLIC_FLAGSMITH_ENV_ID;
const NEXT_PUBLIC_FLAGSMITH_ENABLED = env.NEXT_PUBLIC_FLAGSMITH_ENABLED;

const NEXT_PUBLIC_GOOGLE_ANALYTICS_ID = env.NEXT_PUBLIC_GOOGLE_ANALYTICS_ID;

function App({ Component, pageProps, flagsmithState }: Props) {
  const colorSchemeManager = localStorageColorSchemeManager({
    key: 'mantine-color-scheme',
  });

  const theme = useMainTheme();

  const { isCookieAccepted } = useCookiesAccepted();

  return (
    <>
      <Head>
        <title>{'Moonbeam Dapps'}</title>
        <meta
          name={'viewport'}
          content={'minimum-scale=1, initial-scale=1, width=device-width'}
        />
      </Head>
      <TRPCReactProvider>
        <ApiProvider>
          <FlagsmithProvider serverState={flagsmithState} flagsmith={flagsmith}>
            <MantineProvider
              theme={theme}
              defaultColorScheme={'dark'}
              colorSchemeManager={colorSchemeManager}
              cssVariablesResolver={cssVariablesResolver}
            >
              <RecoilRoot>
                <Notifications
                  position={'top-right'}
                  limit={5}
                  autoClose={4000}
                />
                <EvmWalletProvider>
                  <TermsModal />
                  <CookieConsentModal />
                  <WalletSetup />

                  <AppShell>
                    <Component {...pageProps} />
                  </AppShell>
                </EvmWalletProvider>
              </RecoilRoot>
            </MantineProvider>
          </FlagsmithProvider>
        </ApiProvider>
      </TRPCReactProvider>
      {NEXT_PUBLIC_GOOGLE_ANALYTICS_ID && isCookieAccepted && (
        <GoogleAnalytics gaId={NEXT_PUBLIC_GOOGLE_ANALYTICS_ID} />
      )}
    </>
  );
}

// Modify Flagsmith initialization to work with tRPC
App.getInitialProps = async () => {
  if (!NEXT_PUBLIC_FLAGSMITH_ENABLED) {
    console.warn(
      `Flagsmith is disabled, NEXT_PUBLIC_FLAGSMITH_ENABLED: ${NEXT_PUBLIC_FLAGSMITH_ENABLED}, typeof NEXT_PUBLIC_FLAGSMITH_ENABLED: ${typeof NEXT_PUBLIC_FLAGSMITH_ENABLED}`,
    );
    return { flagsmithState: {} };
  } else {
    await flagsmith.init({
      environmentID: NEXT_PUBLIC_FLAGSMITH_ENV_ID,
    });

    return { flagsmithState: flagsmith.getState() };
  }
};

export const getServerSideProps: GetServerSideProps = async ({ locale }) => {
  return {
    props: {
      ...(await serverSideTranslations(
        locale as string,
        ['common'],
        nextI18NextConfig,
      )),
    },
  };
};

// Export AppWithTRPC instead of App

export default appWithTranslation(
  App as React.ComponentType<Props>,
  nextI18NextConfig,
);
