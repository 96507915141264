import { ActionIcon } from '@mantine/core';
import { useIsDarkMode } from 'hooks/useIsDarkMode';
import { useThemeColors } from 'hooks/useThemeColors';
import { IoEye, IoEyeOff } from 'react-icons/io5';
import { useUserSettings } from 'recoil/userSettings';
import classes from './Buttons.module.css';

export const HideBalances = () => {
  const { areBalancesVisible, toggleBalancesVisibility } = useUserSettings();
  const { themeColors } = useThemeColors();
  const isDark = useIsDarkMode();

  return (
    <ActionIcon
      className={classes.icon}
      size={52}
      variant={'outline'}
      onClick={toggleBalancesVisibility}
      title={areBalancesVisible ? 'Hide balances' : 'Show balances'}
      style={{
        backgroundColor: isDark ? 'transparent' : themeColors.navbarBackground,
      }}
    >
      {areBalancesVisible ? <IoEyeOff size={25} /> : <IoEye size={25} />}
    </ActionIcon>
  );
};
