import { Box, Group, Paper, Text, Title, Tooltip } from '@mantine/core';
import { MoonChainButton } from 'components/MoonChainButton';
import {
  moonbaseConfig,
  moonbeamConfig,
  moonriverConfig,
} from 'configs/chains';
import { useIsMobile } from 'hooks/useMedia';
import { IoInformationCircleOutline } from 'react-icons/io5';
import classes from './MoonChainSection.module.css';

export const MoonChainSection = () => {
  const isMobile = useIsMobile();

  return (
    <Paper px={'lg'} pb={'sm'} className={classes.chainPaper}>
      <Title order={4} display={'inline-block'}>
        {'Networks'}
      </Title>
      <Tooltip
        label={
          <>
            <Text>{'Navigate through our different networks.'}</Text>
            <Text>
              {
                'Moonbase is our testnet and Moonriver is the sister network of Moonbeam.'
              }
            </Text>
          </>
        }
        withArrow
      >
        <Box ml={'xs'} display={'inline-block'}>
          <IoInformationCircleOutline />
        </Box>
      </Tooltip>
      <Group my={'md'} gap={'md'} justify={isMobile ? 'center' : undefined}>
        {[moonbeamConfig, moonriverConfig, moonbaseConfig].map((config) => (
          <MoonChainButton
            key={config.key}
            classNames={{ root: classes.root }}
            chain={config}
          />
        ))}
      </Group>
    </Paper>
  );
};
