import { Modal, Stack } from '@mantine/core';
import { MoonChainButton } from 'components/MoonChainButton';
import {
  moonbaseConfig,
  moonbeamConfig,
  moonriverConfig,
} from 'configs/chains';
import { Dispatch, SetStateAction } from 'react';

interface ChangeMoonChainModalProps {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
}

export const ChangeMoonChainModal = ({
  isOpen,
  setIsOpen,
}: ChangeMoonChainModalProps) => {
  return (
    <Modal
      centered
      opened={isOpen}
      onClose={() => setIsOpen(false)}
      title={'Select your network'}
      closeButtonProps={{ size: 'sm' }}
    >
      <Stack align={'stretch'} gap={'sm'} mt={'sm'}>
        <MoonChainButton chain={moonbeamConfig} />
        <MoonChainButton chain={moonriverConfig} />
        <MoonChainButton chain={moonbaseConfig} />
      </Stack>
    </Modal>
  );
};
